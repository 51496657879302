<template>
    <div class="w-[670px] px-[9px] pt-[14px] pb-[153px] h-[160px] rounded-[10px] border-[1px] border-[#252526]">
        <div class="w-[99%] mx-auto bg-[#4EAE3B] text-[15px] rounded-[5px] text-center font-bold">ІНШЕ</div>
        <div class="flex items-center w-full mx-auto justify-between py-[16px] ">
            <div class="text-[13px] ml-[3px] font-semibold text-[#FFFFFF] w-[45%]">Підключити сповіщення у Telegram</div>
            <div class="flex text-[12px] items-center text-[#4EAE3B]">підключіть та отримуйте сповіщення <img src="../../assets/message_icon.svg" class="w-[20px] h-[20px] mx-[11px]"></div>
            <div class=" flex items-center mr-[9px]"><IphoneButton :isOnValue="botSubscribed" @click="$emit('subscribeBot')"/></div>
        </div>
        <div class="h-[1px] w-full mx-auto bg-[#FFFFFF] bg-opacity-50"></div>
        <div class="flex items-center w-full mx-auto justify-between mt-[14px] mb-[9px] ">
            <div class="text-[13px] font-semibold text-[#FFFFFF] w-[45%]">Правила клубу ФК “Стандарт”</div>
            <div class="flex text-[12px] items-center text-[#4EAE3B]"><span class="relative right-[10px]">ознайомтеся та надайте погодження</span> <img src="../../assets/folder_icon.svg" class="w-[20px] h-[20px]  relative bottom-[3px]"></div>
            <div class=" flex items-center mr-[9px]"><IphoneButton :isOnValue="clubRulesAgreement" @click="$router.push('/school-rules')" /></div>
        </div>
        <div class="flex items-center w-full mx-auto justify-between ">
            <div class="text-[13px] font-semibold text-[#FFFFFF] w-[45%]">Договір про обробку персональних даних</div>
            <div class="flex text-[12px] items-center text-[#4EAE3B]"><span class="relative right-[10px]">ознайомтеся та надайте погодження</span> <img src="../../assets/folder_icon.svg" class="w-[22px] h-[22px] relative bottom-[3px]"></div>
            <div class=" flex items-center mr-[9px]"><IphoneButton :isOnValue="personalDataAgreement" @click="$router.push('/agreement-personal-data')" /></div>
        </div>
    </div>
</template>

<script>
    import IphoneButton from './IphoneButton.vue';

    export default {
        name: 'OtherSettingsBlock',

        props: {
            botSubscribed: {
                type: Boolean,
                default: false
            },
            personalDataAgreement: {
                type: Boolean,
                default: false
            },
            clubRulesAgreement: {
                type: Boolean,
                default: false
            }
        },

        components: {
            IphoneButton
        }
    }
</script>

<style lang="scss" scoped>

</style>